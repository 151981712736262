import { useCallback, useEffect } from "react";
import { useLazyGetUserRolesQuery } from "../apis/usersApi";
import { setUserRoles } from "../slices/authenticationSlice";
import { useAppDispatch } from "./hooks";

export function useUserRoles() {
    const dispatch = useAppDispatch();
    const [getUserRoles, { data: roles, isLoading, isSuccess, isError, isUninitialized }] = useLazyGetUserRolesQuery();

    useEffect(() => {
        if (roles) {
            dispatch(setUserRoles(roles))
        }
    }, [dispatch, roles])
    const initializeRoles = useCallback(
        () => {
            if (isUninitialized) {
                void getUserRoles({ version: 1 })
            }
        },
        [getUserRoles, isUninitialized],
    )

    return { initializeRoles, isUserRolesLoading: isLoading, isUserRolesSuccess: isSuccess, isUserRolesError: isError }
}