import { useEffect } from "react";
import { SelfHelpProvider } from "./components/SelfHelp/SelfHelpProvider";
import { PageLayout } from "./pages/layout/PageLayout";
import { useUserRoles } from "./app/hooks/useUserRoles";
import { UnauthorizedPage } from "./components/errors/UnauthorizedPage";
import { CircularProgressBar } from "./controls/ProgressBar/CircularProgressBar";
import { EmptyPageDiv } from "./pages/layout/SC/EmptyPageDiv";

export function Content() {
    const { initializeRoles, isUserRolesError, isUserRolesLoading, isUserRolesSuccess, } = useUserRoles();

    useEffect(() => {
        initializeRoles()
    }, [initializeRoles]);

    if (isUserRolesLoading) {
        return (
            <EmptyPageDiv>
                <CircularProgressBar
                    color="primary"
                    size={80}
                    message="Loading..."
                    messageFontSize={28}
                />
            </EmptyPageDiv>
        );
    }

    if (isUserRolesSuccess) {
        return (
            <>
                <PageLayout />
                <SelfHelpProvider />
            </>
        );
    }

    if (isUserRolesError) {
        return <UnauthorizedPage />;
    }

    return <></>
}