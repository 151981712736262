import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

interface AuthenticationState {
  userEmail: string;
  userName: string;
  userPhoto?: string;
  roles: string[];
  isSuperAdmin: boolean;
  accountId?: string;
  qaAuthenticateAs?: string;
  qaRoles?: string;
  qaAccessToken?: string;
  qaGraphApiAccessToken?: string;
}

const initialState = {
  ...getInitialAuthenticationState(),
} as AuthenticationState;

export const authStorageWhitelist = [
  "qa_token",
  "qa_authenticate_as",
  "qa_roles",
];

function getInitialAuthenticationState() {
  const qaToken = localStorage.getItem("qa_token");
  const qaGraphApiAccessToken = localStorage.getItem(
    "qa_token_graph_api_token"
  );
  const qaAuth = localStorage.getItem("qa_authenticate_as");
  const qaRoles = localStorage.getItem("qa_roles");

  if (qaToken && qaAuth) {
    return {
      userEmail: "",
      userName: "",
      userPhoto: undefined,
      roles: [],
      isSuperAdmin: false,
      qaAuthenticateAs: qaAuth,
      qaRoles: qaRoles,
      qaGraphApiAccessToken: qaGraphApiAccessToken,
      qaAccessToken: qaToken,
      accountId: "qa_account",
    } as AuthenticationState;
  }

  return {
    userEmail: "",
    userName: "",
    roles: [],
    isSuperAdmin: false,
  } as AuthenticationState;
}

export const authenticationSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    setUserEmail(state: AuthenticationState, action: PayloadAction<string>) {
      state.userEmail = action.payload;
    },
    setUserName(state: AuthenticationState, action: PayloadAction<string>) {
      state.userName = action.payload;
    },
    setUserPhoto(
      state: AuthenticationState,
      action: PayloadAction<string | undefined>
    ) {
      state.userPhoto = action.payload;
    },
    setUserRoles(state: AuthenticationState, action: PayloadAction<string[]>) {
      state.roles = action.payload;
      state.isSuperAdmin = action.payload.includes("SuperAdmin");
    },
  },
});

export const { setUserEmail, setUserName, setUserPhoto, setUserRoles } =
  authenticationSlice.actions;

export const selectUserRoles = (state: RootState) => state.authentication.roles;
export const selectUserEmail = (state: RootState) =>
  state.authentication.userEmail;
export const selectUserName = (state: RootState) =>
  state.authentication.userName;
export const selectUserPhoto = (state: RootState) =>
  state.authentication.userPhoto;
export const selectIsSuperAdmin = (state: RootState) =>
  state.authentication.isSuperAdmin;
export const selectQaAccessToken = (state: RootState) =>
  state.authentication.qaAccessToken;
export const selectQaGraphApiAccessToken = (state: RootState) =>
  state.authentication.qaGraphApiAccessToken;
export const selectIsQaAuth = (state: RootState) =>
  state.authentication.accountId == "qa_account" ? true : false;
export const selectIsQaAuthUser = (state: RootState) =>
  state.authentication.qaAuthenticateAs;

export default authenticationSlice.reducer;
