import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { MainViewTabType } from "../../models/MainViewTabType";
import { Environment } from "../../models/environment";

interface MainViewState {
  tabType: MainViewTabType;
  isWindowTooSmall: boolean;
  userAgreedForTooSmallWindow: boolean;
  environment: Environment;
}

const initialState = {
  tabType: MainViewTabType.Groups,
  environment: Environment.Development,
} as MainViewState;

export const windowMinWidth = 1024;
export const windowMinHeight = 520;

export const mainViewSlice = createSlice({
  name: "mainView",
  initialState,
  reducers: {
    setTabType(state: MainViewState, action: PayloadAction<MainViewTabType>) {
      state.tabType = action.payload;
    },
    setIsWindowTooSmall(state: MainViewState, action: PayloadAction<boolean>) {
      state.isWindowTooSmall = action.payload;
    },
    setEnvironment(state: MainViewState, action: PayloadAction<Environment>) {
      state.environment = action.payload;
    },
    setUserAgreedForTooSmallWindow(
      state: MainViewState,
      action: PayloadAction<boolean>
    ) {
      state.userAgreedForTooSmallWindow = action.payload;
    },
  },
});

export const {
  setTabType,
  setIsWindowTooSmall,
  setUserAgreedForTooSmallWindow,
  setEnvironment,
} = mainViewSlice.actions;

export const selectTabType = (state: RootState) => state.mainView.tabType;
export const selectIsWindowToSmall = (state: RootState) =>
  state.mainView.isWindowTooSmall;
export const selectUserAgreedForTooSmallWindow = (state: RootState) =>
  state.mainView.userAgreedForTooSmallWindow;
export const selectEnvironment = (state: RootState) =>
  state.mainView.environment;

export default mainViewSlice.reducer;
