import PostAddIcon from '@mui/icons-material/PostAdd';
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import { useTheme } from "styled-components";
import { useAppSelector } from "../../../app/hooks/hooks";
import { ButtonsDiv } from "./SC/ButtonsDiv";
import { useMemo, useState } from "react";
import { selectEnvironment, selectIsWindowToSmall } from "../../../app/slices/mainViewSlice";
import { HelpMenu } from "./HelpMenu/HelpMenu";
import { TextButtonControl } from "../../../controls/Buttons/TextButtonControl";
import { Environment } from '../../../models/environment';



export function ActionButtons() {
  const environment = useAppSelector(selectEnvironment);
  const isWindowTooSmall = useAppSelector(selectIsWindowToSmall);
  const theme = useTheme();
  const [helpAnchorEl, setHelpAnchorEl] = useState<null | HTMLElement>(null);

  const publishUrl = useMemo(() => {
    switch (environment) {
      case Environment.Test:
        return "https://publish.library.test.abb.com/";
      case Environment.Development:
        return "https://publish.library.test.abb.com/";
      case Environment.Stage:
        return "https://publish.library.stage.abb.com/";
      default:
        return "https://publish.library.abb.com";
    }
  }, [environment])

  return (
    <ButtonsDiv>
      <TextButtonControl
        colors="secondary"
        height={theme.shapes.verticalButtonHeight}
        icon={<PostAddIcon fontSize="small" />}
        id="header-action-publish"
        tooltipText={"Open ABB Library Publish interface"}
        text="Publish UI"
        onClick={() => { window.open(publishUrl, "_blank"); }}
        isVertical={true}
        isCompactView={isWindowTooSmall}
        isVisible={true}
        removeGap={true}
      />

      <TextButtonControl
        colors="secondary"
        height={theme.shapes.verticalButtonHeight}
        onClick={(e) => setHelpAnchorEl(e.currentTarget)}
        icon={<HelpCenterIcon fontSize="small" />}
        id="header-action-help"
        tooltipText={"ABB Library Publishing Interface help materials"}
        text="Help"
        isVertical={true}
        isCompactView={isWindowTooSmall}
        isVisible={true}
        removeGap={true}
      />
      <HelpMenu anchorEl={helpAnchorEl} onClose={() => setHelpAnchorEl(null)} />
    </ButtonsDiv>
  );
}
