import { useCallback, useEffect } from "react";
import { useLazyGetUncompletedSelfHelpQuery } from "../../../app/apis/selfHelpApi";
import useApiErrorHandling from "../../../app/hooks/useApiErrorHandling";
import { useAppDispatch } from "../../../app/hooks/hooks";
import { setUncompletedSections } from "../../../app/slices/selfHelpSlice";

export function useGetSelfHelp() {
  const dispatch = useAppDispatch();

  const [getUncompletedSelfHelp, { data: uncompletedSections, isError, error }] =
    useLazyGetUncompletedSelfHelpQuery();
  useApiErrorHandling({
    isError,
    error,
    customErrorMessage: "Error while fetching self help",
  });

  const fetchUncompletedSelfHelps = useCallback(() => {
    void getUncompletedSelfHelp(
      { version: 1 },
      false
    );
  }, [getUncompletedSelfHelp]);

  useEffect(() => {
    if (uncompletedSections && !isError) {
      dispatch(setUncompletedSections(uncompletedSections));
    } else {
      dispatch(setUncompletedSections([]));
    }

  }, [uncompletedSections, dispatch, isError])

  return { fetchUncompletedSelfHelps };
}
