import { ListItemIcon, ListItemText, Menu } from "@mui/material";
import MenuBookIcon from '@mui/icons-material/MenuBook';
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';
import ErrorIcon from '@mui/icons-material/Error';
import { TooltipTop } from "../../../../controls/Tooltips/ToolipTop";
import { StyledMenuItem } from "../SC/StyledMenuItem";

interface HelpMenuProps {
  anchorEl: null | HTMLElement;
  onClose: () => void;
}

export function HelpMenu(props: HelpMenuProps) {
  return (
    <Menu
      anchorEl={props.anchorEl}
      open={!!props.anchorEl}
      onClose={props.onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <TooltipTop title={"Open incident if something does not work as intended"}>
        <StyledMenuItem onClick={() => window.open("https://abb.service-now.com/_myservices?id=cp2_sc_cat_item&sys_id=1f9969e4db99ef40aabe75e98c96196c", "Report an incident in Abb Library Publishing Interface")}>
          <ListItemIcon>
            <ErrorIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Incident</ListItemText>
        </StyledMenuItem>
      </TooltipTop>

      <TooltipTop title={"Manual explaining all features available to administrators"}>
        <StyledMenuItem onClick={() => window.open("https://search.abb.com/library/Download.aspx?DocumentID=9AKK108469A9794&LanguageCode=en&DocumentPartId=&Action=Launch", "Abb Library Publishing Interface administrator manual")}>
          <ListItemIcon>
            <MenuBookIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Administrator manual</ListItemText>
        </StyledMenuItem>
      </TooltipTop>

      <TooltipTop title={"Environment where User Access management can be tested without impacting end users"}>
        <StyledMenuItem onClick={() => window.open("https://stage.uam.library.abb.com/", "User access management interface Training Environment")}>
          <ListItemIcon>
            <ModelTrainingIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Training environment</ListItemText>
        </StyledMenuItem>
      </TooltipTop>
    </Menu>
  );
}
