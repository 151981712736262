
import { ErrorDisplay } from "./ErrorDisplay";
import { ErrorDescriptionDiv } from "./SC/ErrorDescriptionDiv";

export const UnauthorizedPage = () => {
  return (
    <ErrorDisplay
      errorId={"screen-for-forbidden"}
      errorMessageTitle={`Authorization error`}
    >
      <ErrorDescriptionDiv>
        You are not authorized to access this application because you are not an ABB employee.
      </ErrorDescriptionDiv>
    </ErrorDisplay>
  );
};
